import React from 'react'
import { ThemeProvider } from '@mui/material'
import { graphql, PageProps } from 'gatsby'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import { ReviewPage as ContentfulReviewPage } from '@talentinc/gatsby-theme-ecom/types/review'
import { TopResumeTheme } from '../styles'
import HeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/HeaderV2'

import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import ReviewPage from '@talentinc/gatsby-theme-ecom/components/Reviews/ReviewPage'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import NavTestHeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/NavTestHeaderV2'

const ReviewTemplate: React.FC<
  PageProps<PageDataContextType & { reviewPage: ContentfulReviewPage }>
> = (props) => {
  const { reviewPage, ...pageData } = props.data

  return (
    <ThemeProvider theme={TopResumeTheme}>
      <Layout pageData={pageData}>
        <SEO landingPage={reviewPage} />
        <HeaderV2 />
        <NavTestHeaderV2 />
        <ReviewPage reviewPage={reviewPage} />
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}

export const query = graphql`
  query ReviewPageBySlugAndBrand($slug: String!, $brandName: String!) {
    reviewPage: contentfulReviewPage(
      slug: { eq: $slug }
      brand: { elemMatch: { name: { eq: $brandName } } }
      node_locale: { eq: "en-US" }
    ) {
      ...ReviewPage
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
      contentful_id: { eq: "3Nd6Keg87eW5pGryLZS2ZA" }
    ) {
      ...HeaderV2
    }
    navTestHeaderV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
      contentful_id: { eq: "6yZRtzPjZxKCylBbpXF0oH" }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ReviewTemplate
